import Api from "@/services/Api";
import { defineStore } from "pinia";



export const useCommentStore = defineStore('CommentStore', {
  // state
  state: () => {
    return {
      comments: [],
      urls: [],
      error: ""
    }
  },
  getters: {
    getComments(state){
      return state.comments
    },
    getUrls(state){
      return state.urls
    },
    getError(state){
      return state.error
    }
  },
  actions: {
    async getCommentsById(id_ticket){
      try {
        const { data } = await Api.get('comments', {id_ticket})
        this.comments = data
      } catch (error) {
        console.log(error)
      }
    },
    async createComment(id_ticket, description: string, cnpj: string, exibirChat?:boolean, id_comment?: number | null, urls?: string[]){
      console.log("aqui urls", urls)
      try {
        const { data, error, message } = await Api.post('comment', {id_ticket, description, id_comment, urls, exibirChat, cnpj})
      
        if(error){
          this.error = message
        }

        this.comments = data
        return data
      } catch (error) {
        console.log(error)
      }
    },
    sendUrls(urlList){
      this.urls = urlList
    }
  }
})